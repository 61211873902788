import { SET_TRAIL_DATA } from "../../Action/trail-history/trail-history-action";

const initialState = {
    doc : null,
    searchJson: { page:1,limit:10, search:{} },
    timestamp: Date.now()
}

const TrailTagHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRAIL_DATA:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default TrailTagHistoryReducer;