import { searchCycleCount, searchDevice, searchOrder, searchTag } from "../../../Constant/Api/Api";
import { orderText, ActiveInventoryText, CANCELLED, CONSUME, DISPATCHED, HOLD, INVENTORY, InactiveInventoryText, LOADING, ORDER_INITIATED, ORDER_PICKED, ORDER_RECEIVED, ORDER_RECEIVING, TOLLING, UNHOLD, UNLOADING, inboundOrder, inboundText, incommingOrder, incommingOrderText, outboundOrder, outboundText, readerText , READER, cycleCountText, ACTIVE, FILLING, FILLED, EMPTY, RECHECKING, ORDER, INVENTORY_PICKED, RECHECKED, ORDER_RECEIVED_PARTIALLY} from "../../../Constant/Common/common-variable";


export const DashboardCardData = [
    {
        _id: 0,
        title: 'Inventory',
        displayText: 'Inventory',
        query: { tagType: INVENTORY },
        serverKeys : INVENTORY,
        api: searchTag,
        isParent : true,
        // loadFile : <DashboardInventory/>,
        ApiHit: false,
        autoRefresh : true,
        child: [
            { _id: 0, title: ACTIVE, displayText: ACTIVE, serverKeys : INVENTORY, query: { $or:[{opreationStatus : ACTIVE}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: HOLD, displayText: HOLD, serverKeys : INVENTORY, query: { $or:[{opreationStatus : HOLD}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: DISPATCHED, displayText: DISPATCHED, serverKeys : INVENTORY, query: { $or:[{opreationStatus : DISPATCHED}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: CONSUME, displayText: CONSUME, serverKeys : INVENTORY, query: { $or:[{opreationStatus : CONSUME}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: CANCELLED, displayText: CANCELLED, serverKeys : INVENTORY, query: { $or:[{opreationStatus : CANCELLED}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: TOLLING, displayText: TOLLING, serverKeys : INVENTORY, query: { $or:[{opreationStatus : TOLLING}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: FILLED, displayText: FILLED, serverKeys : INVENTORY, query: { $or:[{status : FILLED}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: EMPTY, displayText: EMPTY, serverKeys : INVENTORY, query: { $or:[{status : EMPTY}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: LOADING, displayText: LOADING, serverKeys : INVENTORY, query: { $or:[{opreationStatus : LOADING}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 1, title: UNLOADING, displayText: UNLOADING, serverKeys : INVENTORY, query: { $or:[{opreationStatus : UNLOADING}],tagType: INVENTORY }, api: searchTag,isChild : true, enableCursor: true, ApiHit: true, autoRefresh : true },
            { _id: 1, title: INVENTORY_PICKED, displayText: INVENTORY_PICKED, serverKeys : INVENTORY, query: {$or:[{opreationStatus : ORDER_PICKED}],tagType: INVENTORY }, api: searchTag,isChild : true, enableCursor: true, ApiHit: true, autoRefresh : true },
            { _id: 1, title: ORDER_RECEIVED, displayText: ORDER_RECEIVED, serverKeys : INVENTORY, query: { $or:[{opreationStatus : ORDER_RECEIVED}],tagType: INVENTORY }, api: searchTag,isChild : true, enableCursor: true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: RECHECKING, displayText: RECHECKING, serverKeys : INVENTORY, query: { $or:[{opreationStatus : RECHECKING}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: RECHECKED, displayText: RECHECKED, serverKeys : INVENTORY, query: { $or:[{opreationStatus : RECHECKED}],tagType: INVENTORY }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
        ],
    },{
        _id: 1,
        title: 'Reader',
        displayText: 'Reader',
        query: {},
        serverKeys : READER,
        api: searchDevice,
        isParent : true,
        // loadFile : <DashboardInventory/>,
        ApiHit: false,
        child: [],
        loadData : true,
        autoRefresh : true
    },{
        _id: 2,
        title: 'Orders',
        displayText: 'Orders',
        query: {},
        serverKeys : ORDER,
        api: searchOrder,
        isParent : true,
        // loadFile : <DashbaordOrder/>,
        ApiHit: false,
        autoRefresh : true,
        child: [
            { _id: 0, title: ORDER_INITIATED, displayText: ORDER_INITIATED, serverKeys : ORDER, query: { $or:[{orderStatus : ORDER_INITIATED}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: ORDER_RECEIVED, displayText: ORDER_RECEIVED, serverKeys : ORDER, query: { $or:[{orderStatus : ORDER_RECEIVED}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 1, title: ORDER_PICKED, displayText: ORDER_PICKED, serverKeys : ORDER, query: {$or:[{opreationStatus : ORDER_PICKED}]}, api: searchTag,isChild : true, enableCursor: true, ApiHit: true, autoRefresh : true },
            { _id: 1, title: ORDER_RECEIVED_PARTIALLY, displayText: ORDER_RECEIVED_PARTIALLY, serverKeys : ORDER, query: {$or:[{orderStatus : ORDER_RECEIVED_PARTIALLY}]}, api: searchOrder,isChild : true, enableCursor: true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: incommingOrderText, displayText: incommingOrderText, serverKeys : ORDER, query: { $or:[{orderStatus : incommingOrderText?.toUpperCase()}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 1, title: LOADING, displayText: LOADING, serverKeys : ORDER, query: { $or:[{orderStatus : LOADING}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 2, title: UNLOADING, displayText: UNLOADING, serverKeys : ORDER, query: { $or:[{orderStatus : UNLOADING}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 3, title: HOLD, displayText: HOLD, serverKeys : ORDER, query: { $or:[{orderStatus : HOLD}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 4, title: UNHOLD, displayText: UNHOLD, serverKeys : ORDER, query: { $or:[{orderStatus : UNHOLD}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 5, title: DISPATCHED, displayText: DISPATCHED, serverKeys : ORDER, query: { $or:[{orderStatus : DISPATCHED}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 6, title: CONSUME, displayText: CONSUME, serverKeys : ORDER, query: { $or:[{orderStatus : CONSUME}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 7, title: CANCELLED, displayText: CANCELLED, serverKeys : ORDER, query: { $or:[{orderStatus : CANCELLED}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 8, title: TOLLING, displayText: TOLLING, serverKeys : ORDER, query: { $or:[{orderStatus : TOLLING}] } , api: searchOrder, enableCursor: true, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: RECHECKING, displayText: RECHECKING, serverKeys : ORDER, query: { $or:[{orderStatus : RECHECKING}]}, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
            { _id: 0, title: RECHECKED, displayText: RECHECKED, serverKeys : ORDER, query: { $or:[{orderStatus : RECHECKED}] }, api: searchTag, isChild : true, ApiHit: true, autoRefresh : true },
        ],
    },{
        _id: 3,
        title: 'Cycle Count',
        displayText: 'Cycle Count',
        query: {},
        serverKeys : cycleCountText,
        api: searchCycleCount,
        isParent : true,
        // loadFile : DashboardInventory,
        ApiHit: false,
        child: [],
        loadData : true,
        autoRefresh : true
    },{
        _id: 4,
        title: 'Errors',
        displayText: 'Errors',
        serverKeys : 'Errors',
        query: { page: 1, limit: 1, search: { isError : true, $or:[{tagType : INVENTORY}]} },
        enableCursor: true,
        isParent : true,
        api: searchTag,
        ApiHit: true,
        active : false,
        autoRefresh : true
    }
]