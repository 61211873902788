import React, { useEffect, useState } from 'react'
import CustomButton from '../../Component/ui/buttons/custom-button'
import { HitApi } from '../../Store/Action/Api/ApiAction'
import { searchTagHistory } from '../../Constant/Api/Api'
import ControlledTable from '../../Component/ui/table/custom-table'
import TagTrailHistory from '../../Component/trail-history/tag-trail-history'
import { useDispatch, useSelector } from 'react-redux'
import { setTrailData } from '../../Store/Action/trail-history/trail-history-action'
import { compareObjects, getTimeDifference } from '../../Utils/Utils'
import { Colors } from '../../Constant/Colors/Color'

export default function Test() {
  const dispatch = useDispatch()
  const reduxTrailData = useSelector(state => state.TrailTagHistoryReducer)
  const [data, setData] = useState()
  const [compareData, setCompareData] = useState(null)

  useEffect(() => {

    console.log('reduxTrailData', reduxTrailData);

  }, [reduxTrailData])

  const handleClick = () => {
    var json = {
      page: 1,
      limit: 1000,
      search: {
        parentId: "673ad61e7cfa377f4a1bef4d"
      }
    }
    HitApi(json, searchTagHistory).then((res) => {
      if (res?.content) {
        dispatch(setTrailData(res))
        CheckDiff(res)
      }
    })
  }


  const CheckDiff = (res) => {
    const allChanges = [];
    for (let i = 0; i < res?.content?.length - 1; i++) {
      const changes = compareObjects(
        res?.content[i],
        res?.content[i + 1],
      );
      if (changes.length > 0) {
        allChanges.push({
          from: i,
          to: i + 1,
          changes: changes,
          updatedAt: res?.content[i + 1]?.updatedAt,
          updatedBy: res?.content[i + 1]?.updatedBy,
          createdAt: res?.content[i + 1]?.createdAt,
          createdBy: res?.content[i + 1]?.createdBy,
        });
      }
    }
    setCompareData(allChanges)
    console.log('allChanges', allChanges);
  }

  const HistorySummary = ({ updatedArr, createdBy, dateAgo, isCreated }) => {
    return (
      <div className="flex mb-5 mt-5">
        <div>
          <p style={{ backgroundColor: Colors.LOGINRED }} className="w-6 h-6 rounded-xl pt-0.5 text-center text-white">A</p>
        </div>
        <div>
          <div className="flex ml-3 mt-0.5">
            <p className="text-black font-bold">{createdBy}&nbsp;</p>
            <p className="text-gray-400 font-bold">-&nbsp;{getTimeDifference(dateAgo)} <span style={{ color: Colors.LOGINRED }}>({isCreated ? 'Created' : 'Updated'})</span></p>
          </div>
          <div className="flex ml-3 mt-1">
            <ui>
              {
                updatedArr?.map((ele, i) => {
                  return (
                    <li className="text-black">{ele}</li>
                  )
                })
              }
            </ui>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {/* 
      <CustomButton text={'Trail History'} onClick={handleClick} />
      <CustomButton text={'Check Diff'} onClick={CheckDiff} />
      <div className='my-10'><TagTrailHistory /></div>
      <ControlledTable

        data={reduxTrailData?.doc?.content}

      /> */}

      {reduxTrailData?.doc?.content && <div className='my-6'>
        <HistorySummary createdBy={"Aman"} isCreated={true} dateAgo={reduxTrailData?.doc?.content?.[0]?.createdAt} updatedArr={['Batch Number - ' + reduxTrailData?.doc?.content?.[0]?.batchNumber]} />
      </div>}

      {
        compareData !== null &&
        compareData?.map((ele, index) => {
          var updatedArr = []
          ele?.changes?.map((item, i) => {
            updatedArr?.push(item?.key + ' change to ' + item?.newValue)
          })


          return (
            <HistorySummary createdBy={"Aman"} isCreated={false} dateAgo={ele?.updatedAt} updatedArr={updatedArr} />
          )
        })
      }


    </div>
  )
}
