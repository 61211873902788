import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ControlledTable from '../../../../Component/ui/table/custom-table'
import { useColumn } from '../../../../Hooks/use-column'
import { GetAddedProductColumn } from './added-product-column'

export default function AddedProduct() {
  const dispatch = useDispatch()
  const reduxOrder = useSelector(state => state.OrderReducer)

  const columns = useMemo(() => GetAddedProductColumn(reduxOrder?.apiJson))
  const { visibleColumns } = useColumn(columns);
  
  return (
    <div>
      <ControlledTable
        data={reduxOrder?.apiJson?.productIds || []}
        columns={columns}
        disablePagination
      />
    </div>
  )
}
