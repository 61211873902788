import React, { useEffect, useState } from 'react'
import SearchableSelect from '../../../../Component/ui/form/select/SearchableSelect'
import CustomInput from '../component/form/custom-input'
import { useDispatch, useSelector } from 'react-redux'
import { setDeviceProfileApiJson } from '../../../../Store/Action/device-master/device-profile/device-profile-action'
import { searchConfig, searchDeviceProfile, updateDeviceProfile } from '../../../../Constant/Api/Api'
import cn from '../../../../Utils/class-names'
import { HitApi } from '../../../../Store/Action/Api/ApiAction'
import useDynamicLoading from '../../../../Hooks/use-dynamic-loading'
import CustomSwitch from '../../../../Component/ui/switch/custom-switch'
import CustomButton from '../component/form/custom-button'
import { ReaderProfileEntry } from '../../../../Constant/Common/Common'
import useCustomAlertController from '../../../../Hooks/use-custom-alert'
import { useModal } from '../../../../shared/modal-views/use-modal'
import { getFormattedDate } from '../../../../Utils/Utils'
import Status from '../../../../Component/ui/common/status'
import { Button, Tooltip } from 'rizzui'
import DeviceHealth from './DeviceHealth'

export default function AddNewDeviceProfile({ errors, msg, setMsg, _id, row }) {
  const dispatch = useDispatch()
  const reduxDevices = useSelector(state => state.DevicesReducer)
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const reduxDeviceProfile = useSelector(state => state.DeviceProfileReducer)
  const [data, setData] = useState(null)
  const { loadingState, setDynamicLoading } = useDynamicLoading();
  const { showCustomAlert } = useCustomAlertController();
  const { closeModal } = useModal()

  useEffect(() => {
    if (_id && data === null) {
      loadDefaultDeviceProfile()
    }

    console.log('data', data);

  }, [data])

  const loadDefaultDeviceProfile = () => {
    var apiJson = reduxDeviceProfile?.apiJson
    var json = { page: 1, limit: 1, search: { _id: _id } }
    HitApi(json, searchDeviceProfile).then((result) => {
      setData({ config: result?.content })
      Object.assign(apiJson, result?.content?.[0])
      dispatch(setDeviceProfileApiJson(apiJson))

    })
  }

  const handleConfigSelect = (e, name) => {
    const { ele } = e;
    const json = reduxDevices?.apiJson;

    if (ele && name === 'configName') {
      json.deviceProfile = json.deviceProfile || {};
      Object.assign(json.deviceProfile, {
        profileUsage: ele?.configName,
        profileId: ele?._id,
      });
      setData(ele)
      dispatch(setDeviceProfileApiJson(ele?.config?.[0]))
    }
  };

  const customOnChange = (e, index) => {
    var json = reduxDeviceProfile?.apiJson
    const { value, name } = e
    Object.assign(json?.capabilities?.[index] || {}, { userEntry: value })
    dispatch(setDeviceProfileApiJson(json))
  }

  const handleSubmit = () => {
    var json = reduxDeviceProfile?.apiJson
    console.log('submit json,', json);
    setDynamicLoading({ fullPage: true })
    HitApi(json, updateDeviceProfile).then((UpdateResult) => {
      console.log('UpdateResult', UpdateResult);
      setTimeout(() => {
        showCustomAlert(UpdateResult)
        closeModal()
        setDynamicLoading({ fullPage: false })
      }, 0);
    })

  }

  return (
    <div className={cn("grid grid-cols-1 gap-4 my-4", _id && 'px-10 pb-6 pt-4')}>
      <div className={cn('justify-between', _id && 'flex')}>
        <SearchableSelect name="configName" _id={_id ? row?.deviceProfile?.profileId : reduxDevices?.apiJson?.deviceProfile?.profileId} label={'Device Profile'} api={searchConfig} getFieldName={'configName'} error={errors} reduxState={reduxDeviceProfile?.apiJson} setAction={setDeviceProfileApiJson} onChange={(e) => handleConfigSelect(e, 'configName')} setData={setData} disabled={!!_id} />
      </div>
      {msg?.['profileId'] && <span className='text-red text-[11px] rizzui-input-error-text'>{msg?.['profileId']}</span>}
      <div className='grid grid-cols-4 gap-4'>
        {data?.config?.[0]?.capabilities?.map((ele, index) => {
          return <div>
            {(row?.deviceType || reduxDevices?.apiJson?.deviceType) !== 'Reader' ? (
              <>
                <CustomInput
                  userMode
                  name={ele?.name}
                  label={`${ele?.name} ${ele?.value}`}
                  parent={reduxDeviceProfile?.apiJson?.capabilities?.[index]}
                  className="capitalize"
                  reduxState={reduxDeviceProfile?.apiJson}
                  setAction={setDeviceProfileApiJson}
                  onChange={(e) => customOnChange(e, index)}
                  enableSwitch
                />
                <DeviceHealth value={ele?.lastScan} />
              </>
            ) : (
              <>
                <SearchableSelect
                  searchable
                  name={ele?.name}
                  label={`${ele?.name} ${ele?.value}`}
                  parent={reduxDeviceProfile?.apiJson?.capabilities?.[index]}
                  defaultOptions={ReaderProfileEntry}
                  getFieldName="value"
                  onChange={(e) => customOnChange(e, index)}
                  enableSwitch
                />
                <DeviceHealth value={ele?.lastScan} />
              </>
            )}
          </div>
        })}
      </div>
      <div className='flex gap-3 justify-end'>
        {_id && <CustomButton type={'submit'} className={''} text={'Update Profile'} onClick={() => handleSubmit(true)} loading={loadingState?.doc?.fullPage} />}
      </div>
    </div>
  )
}
