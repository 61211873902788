import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SearchableSelect from '../../Component/ui/form/select/SearchableSelect'
import { READER, cycleCountText, dashboardText } from '../../Constant/Common/common-variable'
import { searchBuilding } from '../../Constant/Api/Api'
import { GetUserQuery } from '../../Utils/Utils'
import { setDashboardApiJson, setDashboardSelectedData, setDashboardTimestamp } from '../../Store/Action/dashbaord/DashboardAction'
import { GetDashboardApiJson, GetDashboardSelectedData, SetDashboardApiJson, SetDashboardSelectedData, getBuildingFromStorage, setBuildingToStorage } from '../../Storage/Storage'
import { setSearchableSelectSelectedData } from '../../Store/Action/common/searcheable-select/searcheable-select-action'
import Item from './item/item'
import Reader from './reader/reader'

export default function Dashboard() {

  const dispatch = useDispatch()
  const reduxDashboard = useSelector(state => state.DashboardReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer);
  const reduxSelect = useSelector(state => state.SearchableSelectReducer)
  const selectionType = dashboardText
  const t_name = 'currentLocation'

  useEffect(() => {

    if (!reduxDashboard?.apiJson?.currentLocation) {
      CheckSavedApiJson()
    }

  }, [])

  console.log('reduxDashboard', reduxDashboard?.apiJson);

  const CheckSavedApiJson = () => {
    var apiJson = reduxDashboard?.apiJson
    var savedApiJson = GetDashboardApiJson()
    var savedSelectedDataFromStorage = GetDashboardSelectedData()
    var savedBuildindData = getBuildingFromStorage()
    var selectedDataFromRedux = reduxSelect?.selected
    var findItem = savedBuildindData?.find(Obj => Obj?.selectionType === selectionType)

    console.log('findItem', findItem);

    if (findItem) {
      selectedDataFromRedux = [...selectedDataFromRedux, findItem]
      dispatch(setSearchableSelectSelectedData(selectedDataFromRedux))
    }

    // find saved building and add current location into apijson

    if (savedApiJson?.currentLocation) {
      Object.assign(apiJson, { currentLocation: savedApiJson.currentLocation })
    }

    console.log('savedSelectedDataFromStorage', savedSelectedDataFromStorage);
    

    // find Saved Parent and Add into api json
    var findParent = savedSelectedDataFromStorage?.find((Obj) => Obj.isParent)
    if (findParent) {
      Object.assign(apiJson, { selectedParent: findParent })
      // Saving Data into Redux
      dispatch(setDashboardSelectedData(savedSelectedDataFromStorage));
    }

    console.log('savedApiJson', savedApiJson, savedSelectedDataFromStorage);
  }

  const handleBuilingChange = (e, type) => {
    const { label, value, _id } = e
    var apiJson = reduxDashboard?.apiJson
    apiJson.currentLocation = null;
    apiJson.selectedParent = null;
    dispatch(setDashboardSelectedData([]));
    SetDashboardSelectedData([])
    setTimeout(() => {
      apiJson = { ...reduxDashboard?.apiJson, currentLocation: _id };
      delete apiJson?.selectedParent
      delete apiJson.selectedLocation
      dispatch(setDashboardApiJson(apiJson))
      SetDashboardApiJson(apiJson)
      // Saving data into local storage
      if (type === 'init') {
        saveDataToLocalStorage(e)
      }
    }, 0)

  }

  const saveDataToLocalStorage = (e) => {
    let selectedBuilding = getBuildingFromStorage() || [];
    const filteredBuildings = selectedBuilding.filter((Obj) => Obj.selectionType !== selectionType);
    Object.assign(e, { selectionType: selectionType, name: t_name });
    setBuildingToStorage([...filteredBuildings, e]);
    dispatch(setDashboardTimestamp());
  }

  const onClear = () => {
    dispatch(setDashboardSelectedData([]));
    SetDashboardSelectedData([])
    dispatch(setDashboardApiJson({}))
    SetDashboardApiJson({})
    var savedBuildindData = getBuildingFromStorage()
    var filteredBuildings = savedBuildindData.filter((Obj) => Obj.selectionType !== selectionType);
    dispatch(setSearchableSelectSelectedData(filteredBuildings))
    setBuildingToStorage(filteredBuildings);
  }

  const loadData = () => {

  }

  // Loopoing Parent Items
  let parentItem;
  if (reduxDashboard?.apiJson?.currentLocation) {
    parentItem = reduxDashboard?.DashboardCardData?.map((ele, index) => <Item key={index} ele={ele} ApiHit={loadData} />)
  }

  // Loopoing Child Items
  let childItem;
  if (reduxDashboard?.apiJson?.currentLocation && reduxDashboard?.apiJson?.selectedParent?.serverKeys !== READER && reduxDashboard?.apiJson?.selectedParent?.serverKeys !== cycleCountText) {
    childItem = reduxDashboard?.apiJson?.selectedParent?.child?.map((ele, index) => <Item key={index} ele={ele} ApiHit={loadData} />)
  }


  return (
    <div>
      <div className='grid grid-cols-4 gap-4'>
        <SearchableSelect name={t_name} selectionType={dashboardText} placeholder={'Select Building'} type={'filter'} api={searchBuilding} dynamicSearch={GetUserQuery({}, true)} getFieldName={'buildingName'} onChange={(e) => handleBuilingChange(e, 'init')} onClear={onClear} />
      </div>
      {parentItem && <div className='flex flex-wrap gap-2 group my-5'>{parentItem}</div>}
      {childItem && <div className='flex flex-wrap gap-2 group my-5'>{childItem}</div>}
      {reduxDashboard?.apiJson?.selectedParent?.serverKeys === READER && <Reader />}

    </div>
  )
}
