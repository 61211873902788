export const SET_DEVICE_PROFILE_DATA = 'SET_DEVICE_PROFILE_DATA'
export const SET_DEVICE_PROFILE_API_JSON = 'SET_DEVICE_PROFILE_API_JSON'
export const SET_DEVICE_PROFILE_SEARCH_JSON = 'SET_DEVICE_PROFILE_SEARCH_JSON'
export const SET_DEVICE_PROFILE_TIMESTAMP = 'SET_DEVICE_PROFILE_TIMESTAMP'


export const setDeviceProfileData = (data) => ({ type: SET_DEVICE_PROFILE_DATA, value: data });
export const setDeviceProfileApiJson = (data) => ({ type: SET_DEVICE_PROFILE_API_JSON, value: data });
export const setDeviceProfileSearchJson = (data) => ({ type: SET_DEVICE_PROFILE_SEARCH_JSON, value: data });
export const setDeviceProfileTimestamp = (data) => ({ type: SET_DEVICE_PROFILE_TIMESTAMP, value: data });

