import { outboundText } from "../../../Constant/Common/common-variable";
import { validationSchema } from "../validationSchema";

export const orderSchema = (json) =>({
    orderDateTime: validationSchema?.number()?.required('Order Date Field is Required'),
    expectedArrival : validationSchema?.number('Expected Arrival Field is Required'),
    orderStatus : validationSchema?.string('Order Status Field is Required'),
    orderType : validationSchema?.string('Order Type Field is Required'),
    saleType : json?.orderType === outboundText? validationSchema?.string('Sale Type Field is Required') : validationSchema?.string()?.optional(),
    batchNumber : json?.enableBatch?validationSchema?.string('Batch Number Date Field is Required'):validationSchema?.string()?.optional(),
    productIds : validationSchema?.array('Add at least 1 product'),
    vehicleIds :  validationSchema?.string()?.optional(),
    billTo : validationSchema?.string('Bill To Field is Required'),
    shipTo : validationSchema?.string('Ship To Field is Required'),
});
