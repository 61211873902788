import { use } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBuildingFromStorage, setBuildingToStorage } from '../../Storage/Storage'
import { setSearchableSelectSelectedData } from '../../Store/Action/common/searcheable-select/searcheable-select-action'
import { inboundText } from '../../Constant/Common/common-variable'
import PageHeader from '../../shared/page-header'
import { ScreenName } from '../../Constant/Screen/Screen'
import { routes } from '../../config/routes'
import { useColumn } from '../../Hooks/use-column'
import { setOrderApiJson, setOrderData, setOrderSearchJson } from '../../Store/Action/order/order-action'
import { HitApi } from '../../Store/Action/Api/ApiAction'
import { searchOrder } from '../../Constant/Api/Api'
import useDynamicLoading from '../../Hooks/use-dynamic-loading'
import { CompileOrderData } from '../order/promiss/order-promiss'
import { setPagination } from '../../Store/Action/Pagination/PaginationAction'
import ControlledTable from '../../Component/ui/table/custom-table'
import { TableClass } from '../../Constant/Classes/Classes'
import { GetInboundOrderColumn } from './inbound-column'
import { useStorage } from '../../Hooks/use-storage'

export default function InboundOrder() {
    const dispatch = useDispatch()
    const selectionType = inboundText
    const reduxOrder = useSelector(state => state.OrderReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer);
    const reduxSelect = useSelector(state => state.SearchableSelectReducer)
    const { loadingState, setDynamicLoading } = useDynamicLoading()

    const [render, setRender] = useState(Date.now())

    useEffect(() => {
        if (reduxOrder?.doc === null) {
            checkStorage()
        }

    }, [])

    const checkStorage = () => {
        
        var selectedBuilding = getBuildingFromStorage()
        var selectedDataFromRedux = reduxSelect?.selected
        var findItem = selectedBuilding?.find(Obj => Obj?.selectionType === selectionType)
        if (findItem) {
            selectedDataFromRedux = [...selectedDataFromRedux, findItem]
            dispatch(setSearchableSelectSelectedData(selectedDataFromRedux))
            handleBuilingChange(findItem)
        }
    }


    const saveDataToLocal = (e) => {
        let selectedBuilding = getBuildingFromStorage() || [];
        const filteredBuildings = selectedBuilding.filter((Obj) => Obj.selectionType !== selectionType);
        Object.assign(e, { selectionType: selectionType, name : 'currentLocation'});
        setBuildingToStorage([...filteredBuildings, e]);

    }

    const handleBuilingChange = (e) => {
        const { value, _id } = e
        var apiJson = reduxOrder?.apiJson
        var searchJson = reduxOrder?.searchJson
        Object.assign(apiJson, { orderType: inboundText, currentLocation: _id })
        Object.assign(searchJson.search, { orderType: inboundText, currentLocation: _id })
        dispatch(setOrderApiJson(apiJson))
        dispatch(setOrderSearchJson(searchJson))
        saveDataToLocal(e)
        loadData(_id, 'init')

    }
    

    const loadData = (_id, type) => {
        var searchJson = reduxOrder?.searchJson
        if (type === 'init') {
            Object.assign(searchJson, { page: 1, limit: reduxPagination?.doc?.limit });
        } else {
            Object.assign(searchJson, { page: reduxPagination?.doc?.number, limit: reduxPagination?.doc?.limit });
        }
        setDynamicLoading({ order: true })
        HitApi(searchJson, searchOrder).then((result) => {
            if (result?.content?.[0]) {
                CompileOrderData(result, reduxPagination?.doc).then((compiledData) => {
                    dispatch(setOrderData(compiledData));
                    dispatch(setPagination({
                        limit: searchJson?.limit,
                        totalPages: compiledData?.totalPages,
                        number: compiledData?.number,
                        totalElements: compiledData?.totalElements,
                    }));
                    setDynamicLoading({ order: false })
                })
            } else {
                dispatch(setOrderData([]));
                setDynamicLoading({ order: false })
            }
            setRender(Date.now())
        })
    }

    const onClear = () => {
        var searchJson = reduxOrder?.searchJson
        Object.assign(searchJson, { page: 1, limit: reduxPagination?.doc?.limit, search: {} })
        dispatch(setOrderSearchJson(searchJson))
        // resetting or removeing selected builing from local storage
        var selectedBuilding = getBuildingFromStorage() || []
        var filterItems = selectedBuilding?.filter(Obj => Obj?.selectionType !== inboundText)
        setBuildingToStorage(filterItems)
        dispatch(setOrderData([]));
    }

    const columns = useMemo(() => GetInboundOrderColumn())
    const { visibleColumns, checkedColumns, setCheckedColumns } = useColumn(columns);

    return (
        <div>
            <PageHeader
                screen={ScreenName?.inboundOrder}
                href={routes?.panel?.order?.createInboundOrder + '/' + reduxOrder?.apiJson?.currentLocation + '/' + reduxOrder?.apiJson?.orderType}
                columns={columns}
                checkedColumns={checkedColumns}
                setCheckedColumns={setCheckedColumns}
                json={reduxOrder?.searchJson}
                setAction={setOrderApiJson}
                onChange={(e) => handleBuilingChange(e)}
                onClear={onClear}
                disableCreate={reduxOrder?.searchJson?.search?.currentLocation === undefined && true}
            />

            <ControlledTable
                screen={ScreenName?.inboundOrder}
                variant="modern"
                isLoading={loadingState?.doc?.order}
                data={reduxOrder?.doc?.content}
                columns={visibleColumns}
                className={TableClass}
                json={reduxOrder?.searchJson}
                setAction={setOrderSearchJson}
                ApiHit={loadData}
            />

        </div>
    )
}
