import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UserManagement from '../WebView/user-management/users/user-management'
import RolesAndPermission from '../WebView/user-management/roles-and-permission/roles-and-permission'
import { routes } from '../config/routes'
import SiteMaster from '../WebView/master/site-master/site-master'
import BuildingsMaster from '../WebView/master/buildings-master/buildings-master'
import ReaderHealthMaster from '../WebView/master/reader-health-master/reader-health-master'
import ProductMaster from '../WebView/master/product-master/product-master'
import CustomerMaster from '../WebView/master/customer-master/customer-master'
import SupplierMaster from '../WebView/master/supplier-master/supplier-master'
import GeneralMaster from '../WebView/master/general-master/general-master'
import ReaderBuildingMappingMaster from '../WebView/master/reader-building-mapping-master/reader-building-mapping-master'
import CreateSupplier from '../WebView/master/supplier-master/create/create-supplier'
import Test from '../WebView/test/test'
import ReaderConfiguraiton from '../WebView/configuration/reader-configuraiton'
import VehicleMaster from '../WebView/master/vehicle-master/vehicle-master'
import ZoneMaster from '../WebView/master/zone-master/zone-master'
import LocationMaster from '../WebView/master/location-master/location-master'
import TagMaster from '../WebView/master/tag-master/tag-master'
import ConfigurationMaster from '../WebView/master/configuration-master/configuration-master'
import AddConfigurationMaster from '../Form/master/configuration-master/add-configuration-master'
import MappingMaster from '../WebView/master/mapping-master/mapping-master'
import AddCustomeMaster from '../Form/master/customer-master/add-customer-master'
import Devices from '../WebView/device-manager/devices/devices'
import Brokers from '../WebView/device-manager/brokers/brokers'
import Mapper from '../WebView/device-manager/mapper/mapper'
import ApiService from '../WebView/device-manager/api-service/api-service'
import AddDevice from '../WebView/device-manager/devices/add-device/add-device'
import Certificates from '../WebView/device-manager/certificates/certificates'
import Configuration from '../WebView/device-manager/configuration/configuration'
import AddConfiguration from '../WebView/device-manager/configuration/add/add-configuration'
import AddBroker from '../WebView/device-manager/brokers/add-broker/add-broker'
import AddCertificate from '../WebView/device-manager/certificates/add-certificate/add-certificate'
import AddMapper from '../WebView/device-manager/mapper/add-mapper/add-mapper'
import AddProductMaster from '../Form/master/product-master/add-product-master'
import AddTag from '../WebView/master/tag-master/add/add-tag'
import CreateOrder from '../WebView/order/create/create-order'
import InboundOrder from '../WebView/inbound/inbound-order'
import OutboundOrder from '../WebView/outbound/outbound-order'
import CustomRoleAdd from '../Form/role/custom-role-add'
import Profile from '../WebView/account/profile/profile'
import Blocked from '../WebView/access/blocked/blocked'
import AuthRoute from './auth/AuthRoute'
import ApkUpload from '../WebView/apk-upload/apk-upload'
import { ScreenName } from '../Constant/Screen/Screen'
import Dashboard from '../WebView/dashboard/dashboard'

export default function CustomRoutes() {

    return (
        <Routes>
            <Route path={routes?.panel?.dashboard} exact element={<Dashboard />} />
            <Route path={routes?.panel?.master?.users} exact element={<UserManagement />} />
            <Route path={routes?.panel?.master?.rolesAndPermission} exact element={<AuthRoute screen={ScreenName?.roleAndPermission} element={<RolesAndPermission />}/> } />
            <Route path={routes?.panel?.master?.addRole} exact element={<AuthRoute screen={ScreenName?.roleAndPermission} element={<CustomRoleAdd/>} />} />
            <Route path={routes?.panel?.master?.editRoleRoute} exact element={<CustomRoleAdd/>} />
            <Route path={routes?.panel?.master?.siteMaster} exact element={<SiteMaster />} />
            <Route path={routes?.panel?.master?.buildingsMaster} exact element={<AuthRoute screen={ScreenName?.buildingMaster} element={<BuildingsMaster />} />} />
            
            <Route path={routes?.panel?.device?.readerConfiguration} exact element={<ReaderConfiguraiton />} />
            <Route path={routes?.panel?.device?.readerHealthMaster} exact element={<ReaderHealthMaster />} />
            
            <Route path={routes?.panel?.master?.productMaster} exact element={<ProductMaster />} />
            <Route path={routes?.panel?.master?.createProduct} exact element={<AddProductMaster />} />
            <Route path={routes?.panel?.master?.editProductRoute} exact element={<AddProductMaster />} />
            <Route path={routes?.panel?.master?.customerMaster} exact element={<CustomerMaster />} />
            <Route path={routes?.panel?.master?.createCustomer} exact element={<AddCustomeMaster />} />
            <Route path={routes?.panel?.master?.editCustomerRoute} exact element={<AddCustomeMaster/>} />
            <Route path={routes?.panel?.master?.supplierMaster} exact element={<SupplierMaster />} />
            <Route path={routes?.panel?.master?.createSupplier} exact element={<CreateSupplier />} />
            <Route path={routes?.panel?.master?.editSupplierRoute} exact element={<CreateSupplier/>} />
            <Route path={routes?.panel?.master?.generalMaster} exact element={<GeneralMaster />} />
            <Route path={routes?.panel?.master?.mappingMaster} exact element={<MappingMaster />} />
            <Route path={routes?.panel?.device?.readerBuildingMappingMaster} exact element={<ReaderBuildingMappingMaster />} />

            <Route path={routes?.panel?.master?.vehicleMaster} exact element={<VehicleMaster />} />
            <Route path={routes?.panel?.master?.zomeMaster} exact element={<ZoneMaster />} />
            <Route path={routes?.panel?.master?.locationMaster} exact element={<LocationMaster />} />
            <Route path={routes?.panel?.master?.tagMaster} exact element={<TagMaster />} />
            <Route path={routes?.panel?.master?.addTagMaster} exact element={<AddTag />} />
            
            <Route path={routes?.panel?.master?.configurationMaster} exact element={<ConfigurationMaster />} />
            <Route path={routes?.panel?.master?.createConfigurationMaster} exact element={<AddConfigurationMaster />} />
            <Route path={routes?.panel?.master?.editConfigurationMaster} exact element={<AddConfigurationMaster />} />
            {/* <Route path={routes?.panel?.master?.innventoryMaster} exact element={<InventoryMaster />} /> */}
            {/* <Route path={routes?.panel?.master?.inventoryInventories} exact element={<Inventories />} /> */}
            <Route path={routes?.panel?.deviceManager?.devices} exact element={<Devices/>} />
            <Route path={routes?.panel?.deviceManager?.addDevice} exact element={<AddDevice/>} />
            <Route path={routes?.panel?.deviceManager?.editDeviceRoute} exact element={<AddDevice/>} />
            <Route path={routes?.panel?.deviceManager?.broker} exact element={<Brokers/>} />
            <Route path={routes?.panel?.deviceManager?.configuration} exact element={<Configuration/>} />
            <Route path={routes?.panel?.deviceManager?.addConfiguration} exact element={<AddConfiguration/>} />
            <Route path={routes?.panel?.deviceManager?.editConfigurationRoute} exact element={<AddConfiguration/>} />
            
            <Route path={routes?.panel?.deviceManager?.apiService} exact element={<ApiService/>} />
            <Route path={routes?.panel?.deviceManager?.mapper} exact element={<Mapper/>} />
            <Route path={routes?.panel?.deviceManager?.certificates} exact element={<Certificates/>} />
            <Route path={routes?.panel?.deviceManager?.addbroker} exact element={<AddBroker/>} />
            <Route path={routes?.panel?.deviceManager?.editbroker} exact element={<AddBroker/>} />
            <Route path={routes?.panel?.deviceManager?.apiService} exact element={<ApiService/>} />
            <Route path={routes?.panel?.deviceManager?.mapper} exact element={<Mapper/>} />
            <Route path={routes?.panel?.deviceManager?.addMapper} exact element={<AddMapper/>} />
            <Route path={routes?.panel?.deviceManager?.editMapperRoute} exact element={<AddMapper/>} />
            <Route path={routes?.panel?.deviceManager?.certificates} exact element={<Certificates/>} />
            <Route path={routes?.panel?.deviceManager?.addCertificates} exact element={<AddCertificate/>} />
            <Route path={routes?.panel?.deviceManager?.editCertificates} exact element={<AddCertificate/>} />
            <Route path={'/test'} exact element={<Test />} />
            <Route path={'/upload'} exact element={<ApkUpload/>} />
            {/* Orders Section */}
            <Route path={routes?.panel?.order?.inboundOrder} exact element={<InboundOrder/>} />
            <Route path={routes?.panel?.order?.createInboundOrderRoute} exact element={<CreateOrder/>} />
            <Route path={routes?.panel?.order?.outboundOrder} exact element={<OutboundOrder/>} />
            <Route path={routes?.panel?.order?.createOuboundOrderRoute} exact element={<CreateOrder/>} />
            <Route path={routes?.panel?.order?.editInboundOrderRoute} exact element={<CreateOrder/>} />
            
            {/* Testing Something */}
            <Route path={routes?.panel?.test?.customRole} exact element={<CustomRoleAdd/>} />
            <Route path={routes?.panel?.account?.myProfile} exact element={<Profile/>} />

            <Route path={routes?.panel?.access?.blocked} element={<Blocked />} />
            
        </Routes>
    )
}
// editCustomer