import { deviceProfileVariable } from "./common-variable"

export const StatusOptions = [
    { _id: 0, label: 'Active', value: 'active', status: true, },
    { _id: 1, label: 'In Active', value: 'in-active', status: true, },
]

export const ReportTypeOptions = [
    { _id: 0, label: 'Hourly', value: 'hourly', status: true, },
    { _id: 0, label: 'Daily', value: 'daily', status: true, },
    { _id: 1, label: 'Weekly', value: 'weekly', status: true, },
    { _id: 1, label: 'Monthly', value: 'monthly', status: true, },
]

export const TagOptions = [
    { _id: 0, label: 'ZONE', value: 'ZONE', status: true, },
    { _id: 0, label: 'LOCATION', value: 'LOCATION', status: true, },
    { _id: 1, label: 'INVENTORY', value: 'INVENTORY', status: true, },
]

export const ReaderProfileEntry = [
    { _id: 0, label: deviceProfileVariable?.ENTRY, value: deviceProfileVariable?.ENTRY },
    { _id: 0, label: deviceProfileVariable?.ENTRYEXIT, value: deviceProfileVariable?.ENTRYEXIT },
    { _id: 1, label: deviceProfileVariable?.EXIT, value: deviceProfileVariable?.EXIT },
    { _id: 4, label: deviceProfileVariable?.LOADING, value: deviceProfileVariable?.LOADING },
    { _id: 5, label: deviceProfileVariable?.BAGGING_MANUAL, value: deviceProfileVariable?.BAGGING_MANUAL },
    { _id: 6, label: deviceProfileVariable?.BAGGING_AUTO, value: deviceProfileVariable?.BAGGING_AUTO },
    { _id: 6, label: deviceProfileVariable?.CRANE, value: deviceProfileVariable?.CRANE },
  ]
  