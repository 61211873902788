import React from 'react';
import Status from '../../../../Component/ui/common/status';
import { getFormattedDate } from '../../../../Utils/Utils';

export const DeviceHealth = ({ value }) => {
    if (value == null) {
        return null;
    }

    const fullDate = getFormattedDate(value, ['date', 'month', 'year', 'hour', 'minute', 'second']);
    const lastScan = getFormattedDate(value, ['minute']);

    let statusValue = 'Critical'; // Default status
    if (lastScan <= 15) {
        statusValue = 'Good';
    } else if (lastScan > 15 && lastScan <= 60) {
        statusValue = 'Fair';
    }

    return (
        <label className="flex justify-between items-center">
            <span>{fullDate}</span>
            <Status value={statusValue} className="my-2" />
        </label>
    );
};

export default DeviceHealth;
