import React, { useEffect, useState, useMemo } from "react";
import { HitApi } from "../../../Store/Action/Api/ApiAction";
import { HeaderCell } from "../../../Component/ui/table";
import { getDocCount } from "../../../Constant/Api/Api";
import Skeleton from "react-loading-skeleton";
import { Text } from "rizzui";


export const GetProductColumn = (parentRow) => {
    const [inventoryData, setInventoryData] = useState({});
    const [loading, setLoading] = useState(false);

    // Fetch inventory count when `parentRow` changes
    useEffect(() => {
        if (parentRow?._id && parentRow?.orderStatus) {
            const fetchInventoryCount = async () => {
                setLoading(true);
                const json = {
                    page: 1,
                    limit: 1,
                    search: {
                        orderId: parentRow?._id,
                        opreationStatus: parentRow?.orderStatus,
                    },
                    collectionName: "tagCollectiontrail",
                };
                try {
                    console.log("Fetching inventory count with JSON:", json);
                    const res = await HitApi(json, getDocCount);
                    if (res) {
                        setInventoryData((prev) => ({
                            ...prev,
                            [parentRow._id]: res?.count || 0,
                        }));
                    }
                } catch (error) {
                    console.error("Error fetching inventory data:", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchInventoryCount();
        }
    }, [parentRow]);

    // Memoize the columns to prevent unnecessary re-renders
    const columns = useMemo(() => [
        {
            title: <HeaderCell title="#" />,
            dataIndex: "index",
            key: "index",
            width: 10,
            render: (value, row, index) =>
                loading ? <Skeleton /> : <Text>{index + 1 || "---"}</Text>,
        },
        {
            title: <HeaderCell title="Product Code" />,
            dataIndex: "productCode",
            key: "productCode",
            width: 50,
            render: (value, row) =>
                loading ? <Skeleton /> : <Text>{row?.productId?.productCode || "---"}</Text>,
        },
        {
            title: <HeaderCell title="Product Name" />,
            dataIndex: "productName",
            key: "productName",
            width: 50,
            render: (value, row) =>
                loading ? <Skeleton /> : <Text>{row?.productId?.productName || "---"}</Text>,
        },
        {
            title: <HeaderCell title="Product Grade" />,
            dataIndex: "grade",
            key: "grade",
            width: 50,
            render: (value, row) =>
                loading ? <Skeleton /> : <Text>{row?.productId?.grade || "---"}</Text>,
        },
        {
            title: <HeaderCell title="Product Qty" />,
            dataIndex: "quantity",
            key: "quantity",
            width: 50,
            render: (value, row) =>
                loading ? <Skeleton /> : <Text>{row?.quantity || "---"}</Text>,
        },
        {
            title: <HeaderCell title="Picked Qty" />,
            dataIndex: "pickedQty",
            key: "pickedQty",
            width: 50,
            render: (value, row) =>
                loading ? <Skeleton /> : <Text>{inventoryData?.[parentRow?._id] || 0}</Text>,
        },
        {
            title: <HeaderCell title="Pending Qty" />,
            dataIndex: "pendingQty",
            key: "pendingQty",
            width: 50,
            render: (value, row) =>
                loading ? <Skeleton /> : (
                    <Text>{row?.quantity - (inventoryData?.[parentRow?._id] || 0)}</Text>
                ),
        },
    ], [loading, inventoryData, parentRow]);

    return columns;
};
