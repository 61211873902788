import React, { useEffect, useState } from 'react'
import { refreshEnable } from '../../../Constant/Api/Api';
import { ACTIVE, CANCELLED, CONSUME, DISPATCHED, EMPTY, FILLED, HOLD, INVENTORY, LOADING, ORDER, ORDER_RECEIVING, READER, TOLLING, cycleCountText } from '../../../Constant/Common/common-variable';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { useDispatch, useSelector } from 'react-redux';
import cn from '../../../Utils/class-names';
import { Loader } from 'rizzui';
import { setDashboardApiJson, setDashboardSelectedData } from '../../../Store/Action/dashbaord/DashboardAction';
import { SetDashboardSelectedData } from '../../../Storage/Storage';

export default function Item({ ele, ApiHit }) {
    const dispatch = useDispatch()
    const reduxDashboard = useSelector(state => state.DashboardReducer)
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        let intervalId;
        if (ele?.autoRefresh && ele?.api) {
            setLoading(true);
            loadData();
            if (refreshEnable) {
                intervalId = setInterval(() => {
                    // setLoading(true);
                    loadData();
                }, 5000); // Auto-refresh every 5 seconds
            }
        }
        return () => {
            if (intervalId) clearInterval(intervalId); // Clean up the interval on component unmount
        };
    }, [ele])

    const loadData = () => {
        if (ele?.api) {
            const query = ConstructQuery(ele, reduxDashboard?.apiJson?.currentLocation);
            var json = { page: 1, limit: 2, search: query || {} };
            HitApi(json, ele.api).then((Result) => {
                setData(Result.totalElements || 0);
                setLoading(false);
            });
        }
    }

    const isParentClick = () => {
        console.log('inside isParentClick');
        let apiJson = { ...reduxDashboard?.apiJson }; // Clone apiJson to avoid direct mutation
        var searchJson = { ...reduxDashboard?.searchJson };
        let selectedData = [...(reduxDashboard?.selectedData || [])];
        // remove pre selected data from apiJson
        delete apiJson?.selectedParent
        delete apiJson?.deviceProfile

        var isParent = selectedData?.findIndex((Obj) => Obj.isParent)
        if (isParent !== -1) {
            const existingParent = selectedData[isParent];
            if (existingParent.displayText === ele.displayText) {
                selectedData.splice(isParent, 1)
            } else {
                selectedData.splice(isParent, 1)
                selectedData.push(ele);
                apiJson.selectedParent = ele;
            }
        } else {
            selectedData.push(ele);
            apiJson.selectedParent = ele;
        }
        // Save data into Redux and Local Storage
        dispatch(setDashboardSelectedData(selectedData));
        SetDashboardSelectedData(selectedData)
        //  Updating ApiJson With Current Parent
        dispatch(setDashboardApiJson(apiJson))

        // Construct Json 

    }

    const isChildClick = () => {
        console.log('inside isChildClick');
        let selectedData = [...(reduxDashboard?.selectedData || [])];
        var isChild = selectedData?.findIndex((Obj) => Obj.displayText === ele.displayText)
        if (isChild !== -1) {
            selectedData.splice(isChild, 1)
        } else {
            selectedData.push(ele);
        }
        // Save data into Redux and Local Storage
        dispatch(setDashboardSelectedData(selectedData));
        SetDashboardSelectedData(selectedData)


    }

    const onClick = () => {
        if (ele?.isParent) {
            isParentClick()
        } else {
            isChildClick()
        }
    }


    return (
        <div className='w-48'>
            <div className={cn('card cursor-pointer p-4 justify-between border-2 rounded-md', reduxDashboard?.selectedData?.find((Obj) => Obj.displayText === ele?.displayText) ? 'bg-red-lighter border-red-custom_light' : '')} onClick={onClick}>
                <div className='flex justify-between'>
                    <label className='uppercase'>{ele?.displayText?.replaceAll('_', ' ')}</label>
                    {ele?.serverKeys !== READER && ele?.serverKeys !== cycleCountText && loading ? <Loader /> : data !== null ? data : ele?.api ? <Loader /> : null}
                </div>
            </div>
        </div>
    )
}


export const ConstructQuery = (ele, currentLocation) => {
    const oldQuery = ele?.query || {}
    const inventoryTitle = [ACTIVE, HOLD, DISPATCHED, CONSUME, CANCELLED, TOLLING, FILLED, EMPTY, LOADING, CONSUME, ORDER_RECEIVING];
    switch (ele?.serverKeys) {
        case INVENTORY:
            var tj = Object.assign(oldQuery, { currentLocation: currentLocation })
            return tj;
        case inventoryTitle.includes(ele?.title):
            var tj = Object.assign(oldQuery, { currentLocation: currentLocation })
            return tj;
        case ORDER:
            var tj = Object.assign(oldQuery, { currentLocation: currentLocation })
            return tj;
        case READER:
            var tj = Object.assign(oldQuery, { buildingIds: { $in: [currentLocation] }, deviceType: "Reader" })
            return tj;
        case cycleCountText:
            var tj = Object.assign(oldQuery, { currentLocation: currentLocation })
            return tj;
        default:
            return oldQuery

    }

}