import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../Store/Action/Api/ApiAction'
import { searchDevice } from '../../../Constant/Api/Api'
import { readerText } from '../../../Constant/Common/common-variable'
import ReaderItem from './reader-item'
import { setDashboardApiJson, setDashboardSelectedData } from '../../../Store/Action/dashbaord/DashboardAction'
import cn from '../../../Utils/class-names'
import { SetDashboardSelectedData } from '../../../Storage/Storage'

export default function Reader() {
    const dispatch = useDispatch()
    const reduxDashboard = useSelector(state => state.DashboardReducer)
    const [data, setData] = useState(null)

    useEffect(() => {

        if (reduxDashboard?.apiJson?.currentLocation) {
            loadReaders()
        }

    }, [])

    const loadReaders = () => {
        var json = { page: 1, limit: 50, search: {} }
        Object.assign(json.search, reduxDashboard?.apiJson?.selectedParent?.query)
        HitApi(json, searchDevice).then((Result) => {
            if (Result.content.length > 0) {
                var selectedParent = reduxDashboard?.apiJson?.selectedParent
                selectedParent.child = Result.content?.map((childEle) => Object.assign(childEle, {
                    title: childEle?.deviceName,
                    displayText: childEle?.deviceName,
                    serverKeys: 'Readers',
                    query: childEle?.query,
                    isChild : true
                }))
                dispatch(setDashboardApiJson(reduxDashboard?.apiJson))
            }
        })
    }

    const onClick = (ele) => {
        console.log('inside Reader Child', ele);
        let selectedData = [...(reduxDashboard?.selectedData || [])];
        var isChild = selectedData?.findIndex((Obj) => Obj.displayText === ele?.displayText && Obj.isChild)

        console.log('isChild', isChild);

        if (isChild !== -1) {
            selectedData.splice(isChild, 1)
        } else {
            selectedData.splice(isChild, 1)
            selectedData.push(ele);
        }

        // Save data into Redux and Local Storage
        dispatch(setDashboardSelectedData(selectedData));
        SetDashboardSelectedData(selectedData)
    }

    let readerItem;
    if (reduxDashboard?.apiJson?.currentLocation) {
        readerItem = reduxDashboard?.apiJson?.selectedParent?.child?.map((ele, index) =>
            <div className='w-48'>
                <div className={cn('card cursor-pointer p-4 justify-between border-2 rounded-md', reduxDashboard?.selectedData?.find((Obj) => Obj.displayText === ele?.displayText) ? 'bg-red-lighter border-red-custom_light' : '')} onClick={() => onClick(ele)}>
                    <div className='flex justify-between'>
                        <label className='uppercase'>{ele?.displayText?.replaceAll('_', ' ')}</label>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='flex flex-wrap gap-2 group my-5'>{readerItem}</div>
        </div>
    )
}
