import React, { useEffect, useMemo, useState } from 'react'
import ControlledTable from '../../../Component/ui/table/custom-table'
import { GetProductColumn } from './product-column';
import { useColumn } from '../../../Hooks/use-column';
import { HeaderCell } from '../../../Component/ui/table';
import Skeleton from 'react-loading-skeleton';
import { HitApi } from '../../../Store/Action/Api/ApiAction';
import { getDocCount } from '../../../Constant/Api/Api';
import { Text } from 'rizzui';

export default function ProductList({ row }) {
    const [inventoryData, setInventoryData] = useState([])
    const [rows, setRows] = useState({});

    // const GetProductColumn = (parentRow) => {

    //     useEffect(() => {
    //         if (parentRow?._id && parentRow?.orderStatus) {
    //             const fetchInventoryCount = async () => {
    //                 const json = {
    //                     page: 1,
    //                     limit: 1,
    //                     search: {
    //                         orderId: parentRow?._id,
    //                         opreationStatus: parentRow?.orderStatus,
    //                     },
    //                     collectionName: "tagCollectiontrail",
    //                 };
    //                 console.log('Fetching inventory count with JSON:', json);
    //                 const res = await HitApi(json, getDocCount);
    //                 if (res) {
    //                     setInventoryData((prev) => ({
    //                         ...prev,
    //                         [parentRow._id]: res?.count || 0,
    //                     }));
    //                 }
    //             };

    //             fetchInventoryCount();
    //         }
    //     }, [parentRow])

    //     const renderCell = (value, row, content) => (
    //         rows[row.index]?.loading ? <Skeleton /> : content
    //     );

    //     return [
    //         {
    //             title: <HeaderCell title="#" />,
    //             dataIndex: 'index',
    //             key: 'index',
    //             width: 10,
    //             render: (value, row, index) => renderCell(value, row, <Text>{index + 1 || '---'}</Text>),
    //         },
    //         {
    //             title: <HeaderCell title="Product Code" />,
    //             dataIndex: 'productCode',
    //             key: 'productCode',
    //             width: 50,
    //             render: (value, row) => renderCell(value, row, <Text>{row?.productId?.productCode || '---'}</Text>),
    //         },
    //         {
    //             title: <HeaderCell title="Product Name" />,
    //             dataIndex: 'productName',
    //             key: 'productName',
    //             width: 50,
    //             render: (value, row) => renderCell(value, row, <Text>{row?.productId?.productName || '---'}</Text>),
    //         },
    //         {
    //             title: <HeaderCell title="Product Grade" />,
    //             dataIndex: 'grade',
    //             key: 'grade',
    //             width: 50,
    //             render: (value, row) => renderCell(value, row, <Text>{row?.productId?.grade || '---'}</Text>),
    //         },
    //         {
    //             title: <HeaderCell title="Product Qty" />,
    //             dataIndex: 'quantity',
    //             key: 'quantity',
    //             width: 50,
    //             render: (value, row) => renderCell(value, row, <Text>{row?.quantity || '---'}</Text>),
    //         },
    //         {
    //             title: <HeaderCell title="Picked Qty" />,
    //             dataIndex: 'pickedQty',
    //             key: 'pickedQty',
    //             width: 50,
    //             render: (value, row) => renderCell(value, row, <Text>{inventoryData?.[parentRow?._id]}</Text>),
    //         },
    //         {
    //             title: <HeaderCell title="Pending Qty" />,
    //             dataIndex: 'pendingQty',
    //             key: 'pendingQty',
    //             width: 50,
    //             render: (value, row) => renderCell(value, row, <Text>{(row?.quantity - inventoryData?.[parentRow?._id] || 0)}</Text>),
    //         }
    //     ]
    // }

    const columns = useMemo(() => GetProductColumn(row))

    const { visibleColumns } = useColumn(columns);

    return (
        <div className='p-10'>
            <ControlledTable
                // isLoading={loadingState?.doc?.order}
                data={row?.productIds}
                columns={visibleColumns}
                // className={TableClass}
                // json={reduxOrder?.searchJson}
                // setAction={setOrderApiJson}
                // ApiHit={loadData}
                disablePagination
            />
        </div>
    )
}
